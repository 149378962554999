import React, { Component } from "react"
import { TwitterTweetEmbed } from "react-twitter-embed"
import Layout from "../components/layout"
import Wrap from "../components/ui/Wrap"
import AppBar from "../components/ui/AppBar"

class AboutPage extends Component {
  render() {
    return (
      <Wrap>
        <AppBar />
        <Layout>
          <h1>🐣 How TiltMaps was born</h1>
          <p>
            It all starts when two people fall in love... er... what's the
            question? Ah, you want to know how TiltMaps came to be.
          </p>
          <p>
            Well, back in 2017 I (maybe stupidly) decided to try and launch a
            new side-preject every month. TiltMaps was my project for June.
            Turns out you can't build something like this in one month though so
            for the next 6 months I worked on bring TiltMaps to life.
          </p>
          <p>
            Since launch TiltMaps have shipped out to literally dozens of
            customers. It's been great.
          </p>
          <TwitterTweetEmbed tweetId={"938753950995632128"} />
          <div>
            <img
              style={{ maxWidth: 600 }}
              src={require("../assets/mark.jpg")}
              alt="This is the guy who made it"
            />
            <caption>Me awkwardly posing in front of two tiltmaps</caption>
          </div>
          <h1>Credits</h1>
        </Layout>
      </Wrap>
    )
  }
}

export default AboutPage
